import React, { useEffect, useState, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';

import useWindowDimensions from '../../components/useWindowDimensions';

import { contentAtom, getcontentList, addHTMLRedirect, uploadcontent, createPlaylist, deleteContent, MAX_FILE_SIZE, editPlaylist, editContent } from '../../states/content';

import { useRecoilState } from 'recoil';
import update from "immutability-helper";

import './content.css'
import '../../components/GenericPage/page.css'

import uploadingGif from '../../components/Assets/Uploading/Uploading.gif'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import FormikSelect from '../../components/FormikSelect';
import Loading from '../../components/Loading/Loading';
import LoadingBars from '../../components/Loading/LoadingBars';
import { hasAdminAccess } from '../../states/auth';
// add icons to the library
library.add(fas)

export const ContentComp = ({ setPageName, toggleSidebar }) => {
  const { height, width } = useWindowDimensions()

  const adjustedWidth = toggleSidebar ? width - 245 : width

  const [content, setContent] = useRecoilState(contentAtom)

  const emptyContent = {
    _id: '',
    Name: '',
    Extension: '',
    Path: ''
  }

  const [contentPopup, setContentPopup] = useState(content.length === 0 ? true : false)
  const [selectedContent, setSelectedContent] = useState(emptyContent)

  const [selectedOption, setSelectedOption] = useState('All')

  const [toggleFilter, setToggleFilter] = useState(false)

  const [search, setSearch] = useState({
    name: '',
    extension: '',
  })

  const filterContent = (con) => {
    if (selectedOption === 'In Use') {
      return con.filter(c => c.InUse)
    } else if (selectedOption === 'Not In Use') {
      return con.filter(c => !c.InUse)
    } else {
      return con
    }
  }

  const searchContent = (con) => {
    return con.filter(item => item.Name.toLowerCase().includes(search.name.toLowerCase()) &&
      item.Extension.toLowerCase().includes(search.extension.toLowerCase()))
  }

  const updateSearch = (val) => {
    setSearch(prev => {
      return update(prev, {
        $merge: val
      })
    })
  }

  const updateAtoms = async () => {
    setContent(await getcontentList())
    return
  }

  const selectOption = (option) => {
    if (option !== selectedOption) {
      setSelectedOption(option)
    }
  }
  const submitForm = async (values) => {
    var resp = false
    if (values._id === '') {
      if (values.contentType.id === 'HTML') {
        if (!values.HTMLRedirect.startsWith('https://')) {
          values.HTMLRedirect = 'https://' + values.HTMLRedirect
        }
        resp = await addHTMLRedirect(values.HTMLRedirect, values.Name)

      }

      if (values.contentType.id === 'Content') {
        resp = await uploadcontent(values.File, values.Name)
      }

      if (values.contentType.id === 'Playlist') {
        resp = await createPlaylist(values.Playlist, values.Name)
      }
    } else {
      if (values.contentType.id === 'HTML') {
        if (!values.HTMLRedirect.startsWith('https://')) {
          values.HTMLRedirect = 'https://' + values.HTMLRedirect
        }
        resp = await addHTMLRedirect(values.HTMLRedirect, values.Name)
      }

      if (values.contentType.id === 'Content') {
        if (values.File) {
          resp = await uploadcontent(values.File, values.Name)
        }
      }

      if (values.contentType.id === 'Playlist') {
        resp = await editPlaylist(values.Playlist, values.Name, values._id)
      }

      if (resp) {
        if (typeof resp !== 'string') {
          await editContent({
            id: resp._id,
            name: resp.Name,
            oldID: values._id
          })
        }
      } else {
        await editContent({
          id: values._id,
          name: values.Name
        })
      }
    }

    if (resp) {
      if (typeof resp === 'string') {
        alert(resp)
      } else {
        if (resp.Message) {
          alert(resp.Message)
          if (resp.Content.Exists) {
            alert(`Content Already Exists: ${resp.Content.Name}.`)
          }
        } else if (resp.Exists) {
          alert(`Content Already Exists: ${resp.Name}.`)
        }
      }
    }

    await updateAtoms()
    return
  }

  const handleDeleteContent = async (content) => {
    if (window.confirm(`Delete Content: ${content.Name}?`)) {
      await deleteContent({
        id: content._id,
        name: content.Name
      })
      await updateAtoms()
    }
  }

  useEffect(() => {
    setPageName('Content')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div
        className='pageBody'
        style={{ overflow: 'auto' }}
      >
        <div
          style={{ maxWidth: '1200px' }}
        >
          <div className='contentStickyBox'>
            <div className='contentOptionBar'>
              <div style={{ display: 'flex' }}>
                <ContentOptionBarButton
                  buttonStyle='contentButton'
                  text='All'
                  setVal={selectOption}
                  val={selectedOption}
                />
                <ContentOptionBarButton
                  buttonStyle='contentButton'
                  text='In Use'
                  setVal={selectOption}
                  val={selectedOption}
                />
                <ContentOptionBarButton
                  buttonStyle='contentButton'
                  text='Not In Use'
                  setVal={selectOption}
                  val={selectedOption}
                />
              </div>
              <div
                className='contentButtonEnd'
                style={{ padding: '2px 5px', color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' }}
                onClick={() => {
                  setSelectedContent(emptyContent)
                  setContentPopup(!contentPopup)
                }}
              >
                <FontAwesomeIcon style={{ width: '15px' }} icon={contentPopup ? "fa-solid fa-xmark" : "fa-solid fa-plus"} />
              </div>
            </div>
          </div>
          <div className='contentPageInterfacesScaling'>
            <ContentPopup
              trigger={contentPopup}
              setTrigger={setContentPopup}
              content={selectedContent}
              submitForm={submitForm}
            />
            {content.length > 0 ?
              <div className='contentListTableScaling'>

                <div className='contentListTopbar'
                  style={{
                    height: toggleFilter ? width > 530 ? '73px' : '103px' : '30px',
                  }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #a5a5a5', }}>
                    <div className='contentListTopbarItem'>
                      <span>Content</span>
                    </div>
                    <div className='contentListTopbarItem' style={{ flexDirection: 'row' }}>
                      <FontAwesomeIcon
                        onClick={() => {
                          setToggleFilter(!toggleFilter)
                        }}
                        className='contentFilter'
                        title={toggleFilter ? "Close" : "Filter"}
                        icon={toggleFilter ? "fa-solid fa-xmark" : "fa-solid fa-filter"} />
                    </div>
                  </div>
                  <div className='contentListTopbarItem'
                    style={{
                      transition: 'all 1000ms cubic-bezier(.7,0,.3,1)',
                      opacity: toggleFilter ? 1 : 0,
                      padding: '6px 5px',
                      flexWrap: 'wrap',
                      justifyContent: 'normal',
                      fontSize: '14px',
                      fontWeight: 'normal'
                    }}>
                    {toggleFilter && (
                      <>
                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Name:</span>
                            <input
                              className='contentListSearchField'
                              type="text"
                              placeholder='Search...'
                              value={search.name}
                              onChange={(e) => updateSearch({ name: e.target.value.toLowerCase() })}
                            />
                          </div>
                        </div>

                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Extension:</span>
                            <input
                              className='contentListSearchField'
                              type="text"
                              placeholder='Search...'
                              value={search.extension}
                              onChange={(e) => updateSearch({ extension: e.target.value.toLowerCase() })}
                            />
                          </div>
                        </div>
                      </>)}
                  </div>
                </div>
                {filterContent(searchContent(content)).length > 0 ?
                  <div className='contentListBox'>
                    {filterContent(searchContent(content)).map((content) => (
                      <ContentListEntry
                        key={content._id}
                        content={content}
                        setContentPopup={setContentPopup}
                        setSelectedContent={setSelectedContent}
                        deleteContent={handleDeleteContent}
                        width={adjustedWidth}
                      />
                    ))}
                  </div>
                  :
                  <p style={{ textAlign: 'center', color: '#dddddd', margin: '5px 0px' }}>No Content Matching Search.</p>
                }

              </div>
              :
              <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Content.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const ContentListEntry = ({ content, setContentPopup, setSelectedContent, deleteContent, width }) => {

  return (
    <div className='contentListItem'>
      <div style={{ borderRight: '1px solid #a5a5a5', display: 'flex', justifyContent: 'center' }}>
        <DisplayImage src={content.Path} width={width} thumbnail={content.Thumbnail} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div className='contentListItemEntry' style={{ padding: '2px 5px', fontSize: width > 450 ? '13px' : '11px' }}>
          <span style={{ fontSize: '16px' }}><strong>{content.Name}</strong></span>
          <span><span style={{ color: '#3aa3f5' }}>Extension: </span>{content.Extension}</span>
        </div>
        <div
          className='contentListItemEntry'
          style={{ borderLeft: '1px solid #a5a5a5', width: '25px', justifyContent: 'space-evenly' }}
        >
          {!content.InUse && content.Name !== 'DEFAULT' && (
            <FontAwesomeIcon
              title='Delete'
              className='contentListItemEntryIcon'
              icon="fa-solid fa-trash-can"
              onClick={(e) => deleteContent(content)}
            />)}
          {(content.Name !== 'DEFAULT') && (
            <FontAwesomeIcon
              title='Edit'
              className='contentListItemEntryIcon'
              icon="fa-solid fa-pen-to-square"
              onClick={async (e) => {
                setSelectedContent(content)
                await setContentPopup(false)
                setContentPopup(true)
              }}
            />
          )}
          <FontAwesomeIcon
            title='Create Schedule'
            className='contentListItemEntryIcon'
            icon="fa-solid fa-calendar-plus"
            onClick={() => {
              window.location.href = `/Schedules?${content._id}`
            }}
          />

        </div>
      </div>
    </div >
  )
}

const ContentOptionBarButton = ({ text, buttonStyle, setVal, val }) => {
  return (
    <div
      className={buttonStyle}
      style={text === val ? { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } : {}}
      onClick={() => setVal(text)}
    >
      {text}
    </div>
  )
}

const ContentPopup = ({ trigger, setTrigger, submitForm, content }) => {

  const [uploading, setUploading] = useState(false)

  let uploadTypes = [
    {
      _id: 'Content',
      Name: 'Media'
    },
    {
      _id: 'HTML',
      Name: 'Webpage'
    },
    {
      _id: 'Playlist',
      Name: 'Music Playlist'
    }
  ]

  const initialValues = {
    ...content,
    contentType: {
      id: 'Content',
      Name: 'Media'
    },
    HTMLRedirect: '',
    File: '',
    Playlist: [],
  }

  const validateFormData = (values) => {
    var errors = {}

    if (!(values.Name).trim()) {
      errors.Name = 'Required'
    } else if (values.Name === 'DEFAULT' && !hasAdminAccess()) {
      errors.Name = 'Cannot use name "DEFAULT"'
    }

    if (values.contentType.id === 'Content') {
      if (!values.File && values._id === '') {
        errors.File = 'Required'
      } else if (values.File.size > MAX_FILE_SIZE) {
        errors.File = `Max Upload Size ${MAX_FILE_SIZE / 1000000}mb.`
      }
    }

    if (values.contentType.id === 'HTML') {
      if (!(values.HTMLRedirect).trim()) {
        errors.HTMLRedirect = 'Required'
      }
    }

    if (values.contentType.id === 'Playlist') {
      if (values.Playlist.length === 0) {
        errors.Playlist = 'Required'
      }

      if (values.Playlist.length <= 1) {
        errors.Playlist = 'A playlist must contain more than 1 song.'
      }

      if (values.Playlist.reduce((sum, file) => sum + file.size, 0) > MAX_FILE_SIZE) {
        errors.Playlist = `Max Upload Size ${MAX_FILE_SIZE / 1000000}mb.`
      }
    }


    if (!values.contentType) {
      errors.contentType = 'Required'
    }

    return errors
  }

  return (
    <div style={{
      maxHeight: trigger ? '627px' : '0px',
      opacity: trigger ? 1 : 0,
      transition: 'all 1000ms ease',
      overflow: 'hidden',
      marginBottom: '10px',
      border: trigger ? '1px solid #dddddd' : 'none',
      backgroundColor: '#212121',
    }}>
      {trigger ?
        <div className='contentPopup'>
          <div className='contentPopUpBox'>
            <Formik
              initialValues={initialValues}
              validate={validateFormData}
              onSubmit={async (values, { resetForm }) => {
                setUploading(true)
                await submitForm(values)
                setUploading(false)
                if (values._id !== '') {
                  setTrigger(false)
                }
                resetForm()
              }}
            >
              {({ isSubmitting, values }) => (
                <Form className='contentPopupForm'>
                  {uploading ?
                    <React.Fragment>
                      <p>
                        <img src={uploadingGif} alt='Uploading...' />
                      </p>
                    </React.Fragment>
                    :
                    <>

                      <div style={{ padding: '0px 5px 5px 5px' }}>
                        <h3 className='contentPopupHeader'>
                          {values._id !== '' ? 'Edit Content' : 'New Content'}
                        </h3>

                        <div className='contentPopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Type:</label>
                          <div style={{ width: '282px' }}>
                            <Field
                              name='contentType'
                              component={FormikSelect}
                              options={uploadTypes}
                              height={'130px'}
                            />
                            <ErrorMessage name="contentType" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        <div className='contentPopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Name:</label>
                          <div>
                            <Field
                              className='contentInputField'
                              type="text"
                              name="Name"
                            />
                            <ErrorMessage name="Name" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        {values.contentType.id === 'Content' && (
                          <div className='contentPopupFormGroups'>
                            <label style={{ paddingRight: '5px' }}>Content:</label>
                            <div>
                              <Field
                                component={UploadFile}
                                name="File"
                              />
                              {values._id !== '' && !values.File && (<ErrorMsgPopup msg={'Leave Blank to Use Existing File.'} />)}
                              <ErrorMessage name="File" render={msg => <ErrorMsgPopup msg={msg} />} />
                            </div>
                          </div>
                        )}

                        {values.contentType.id === 'HTML' && (
                          <div className='contentPopupFormGroups'>
                            <label style={{ paddingRight: '5px' }}>Link:</label>
                            <div>
                              <Field
                                className='contentInputField'
                                type="text"
                                name="HTMLRedirect"
                              />
                              <ErrorMessage name="HTMLRedirect" render={msg => <ErrorMsgPopup msg={msg} />} />
                            </div>
                          </div>
                        )}

                        {values.contentType.id === 'Playlist' && (
                          <Field
                            component={PlaylistCreation}
                            name='Playlist'
                            errorMSG={<ErrorMessage name="Playlist" render={msg => <ErrorMsgPopup msg={msg} />} />}
                          />
                        )}


                        <div style={{ display: 'flex', alignItems: 'center' }}>


                          <button
                            className='contentInputField'
                            type="submit"
                            disabled={isSubmitting}
                            style={{ cursor: 'pointer' }}
                          >
                            {isSubmitting ?
                              <div style={{ textAlign: 'center', width: '46px' }}><LoadingBars /></div>
                              :
                              'Submit'
                            }
                          </button>
                        </div>
                      </div>
                    </>}
                </Form>
              )}

            </Formik>
          </div>
        </div>
        : <div style={{ height: '239px' }}></div>}
    </div>
  )
}

const ErrorMsgPopup = ({ msg }) => {
  return (
    <div className='invalidPopupBubble'>
      <p>*</p>
      <p>{msg}</p>
    </div>
  )
}

const DisplayImage = ({ src, width, thumbnail }) => {
  const maxWidth = width > 450 ? '240px' : '100px'
  const maxHeight = width > 450 ? '135px' : '110px'
  return (
    <div style={{
      height: maxHeight,
      width: maxWidth,
      overflow: 'hidden',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <a
        href={src}
        target='_blank'
        rel="noopener noreferrer"
        aria-label='link'
      >
        <img
          style={{
            objectFit: 'contain',
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            color: 'white',
            textDecoration: 'underline',
            marginTop: '7px'
          }}
          src={thumbnail ? thumbnail : src}
          title=""
          alt='Link'
        />
      </a>
    </div>
  )
}

const UploadFile = ({ field, form }) => {
  return (
    <input
      className='contentInputField'
      style={{ fontSize: '13px', fontWeight: 'normal' }}
      type="file"
      onChange={(e) => {
        form.setFieldValue(field.name, e.target.files[0])
      }}
    />
  )
}

const PlaylistCreation = ({ field, form, errorMSG }) => {

  var tempFiles = useRef('')

  const uploadSize = field.value ? field.value.reduce((sum, file) => sum + file.size, 0) : 0

  const selectUploadFiles = (files) => {
    if (files[0] !== undefined) {
      var validFiles = []
      if (field.value) {
        validFiles = [...field.value]
      }
      for (var i = 0; i < files.length; i++) {
        if (files[i].type === 'audio/x-m4a' || files[i].type === 'audio/mpeg') {
          validFiles.push(files[i])
        }
      }

      form.setFieldValue(field.name, validFiles)
      tempFiles.current.value = ''
    }
  }

  const deleteFile = (index) => {
    var files = [...field.value]
    files.splice(index, 1)
    form.setFieldValue(field.name, files)
  }

  return (
    <>
      <div className='contentPopupFormGroups'>
        <label style={{ paddingRight: '5px' }}>Add Songs:</label>
        <div style={{ width: '250px' }}>
          <input
            className='contentInputField'
            style={{ fontSize: '13px', fontWeight: 'normal' }}
            ref={tempFiles}
            multiple
            type="file"
            accept={['.mp3', '.m4a']}
            onChange={(e) => selectUploadFiles(e.target.files)}
          />
          {errorMSG}
        </div>
      </div>
      {field.value.length > 0 && (
        <div className='contentPopupFormGroups'
          style={{
            maxHeight: '300px',
            overflow: 'auto',
            paddingBottom: '0px',
            marginBottom: '20px',
            borderBottom: '1px solid #2b7fbe',
            borderTop: '1px solid #2b7fbe',
            flexDirection: 'column'
          }}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #2b7fbe',
            marginBottom: '5px',
            padding: '0px 5px',
            zIndex: '800',
            top: '0px',
            position: 'sticky',
            backgroundColor: '#212121'
          }}>
            <span>Songs: </span>
            <span style={{ fontWeight: 'normal', fontSize: '12px' }}>Size: {
              (uploadSize / 1000000).toFixed(2)} of {MAX_FILE_SIZE / 1000000} mb</span>
          </div>
          {field.value.map((file, index) => {
            return (
              <div
                key={index}
                style={{
                  marginBottom: '5px',
                  padding: '0px 5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'normal',
                  fontSize: '12px'
                }}>
                <span>{file.name}</span>
                <div style={{ display: 'flex', width: '85px', justifyContent: 'end' }}>
                  <span style={{ marginRight: '15px' }}>{(file.size / 1000000).toFixed(2)} mb</span>
                  <FontAwesomeIcon
                    className='contentFilter'
                    style={{ padding: '0px' }}
                    icon="fa-solid fa-xmark"
                    onClick={() => deleteFile(index)}
                  />
                </div>

              </div>
            )
          })}
        </div>)}
    </>
  )
}


const Content = ({ setPageName, toggleSidebar }) => {
  return (
    <React.Suspense fallback={<Loading color={'white'} />}>
      <ContentComp setPageName={setPageName} toggleSidebar={toggleSidebar} />
    </React.Suspense>
  )
}

export default Content
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardInfoBox {
  height: 350px;
  border: 1px solid #dddddd;
  margin: 10px;
  background-color: #212121;
  max-width: 600px;
}

.dashboardInfoHeaderBox {
  color: #eeeeee;
  padding: 2px 5px 5px 5px;
  border-bottom: 1px solid #dddddd;
}

.dashboardInfoBodyBox {
  color: #eeeeee;
  font-size: 11px;
  overflow: auto;
  height: calc(100% - 37px);
  background-color: #161616;
}

.dashboardInfoScheduleEntry {
  padding: 2px 7px 7px 7px;
  border-bottom: 1px solid #dddddd;
}

.ipListButton {
  background-color: #303030;
  outline: none;
  border: 1px solid #dddddd;
  color: white;
  caret-color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.ipListButton:hover {
  border: 1px solid #2b7fbe;
}
`, "",{"version":3,"sources":["webpack://./src/views/Dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,wBAAwB;EACxB,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dashboardInfoBox {\n  height: 350px;\n  border: 1px solid #dddddd;\n  margin: 10px;\n  background-color: #212121;\n  max-width: 600px;\n}\n\n.dashboardInfoHeaderBox {\n  color: #eeeeee;\n  padding: 2px 5px 5px 5px;\n  border-bottom: 1px solid #dddddd;\n}\n\n.dashboardInfoBodyBox {\n  color: #eeeeee;\n  font-size: 11px;\n  overflow: auto;\n  height: calc(100% - 37px);\n  background-color: #161616;\n}\n\n.dashboardInfoScheduleEntry {\n  padding: 2px 7px 7px 7px;\n  border-bottom: 1px solid #dddddd;\n}\n\n.ipListButton {\n  background-color: #303030;\n  outline: none;\n  border: 1px solid #dddddd;\n  color: white;\n  caret-color: white;\n  padding: 2px 5px;\n  border-radius: 5px;\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.ipListButton:hover {\n  border: 1px solid #2b7fbe;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
